<template>
    <div>
        <div v-for="(item, index) in datas" :key="index" class="relative p-3 border rounded-md shadow-md shadow-gray-100 mt-1 mb-5">
            <div class="grid grid-cols-12 gap-4 px-5 text-base">
                <div class="col-span-12 md:col-span-3 lg:col-span-3  xl:col-span-3 
                    truncate">
                    {{ item.fields.fldu6FfahVzjX }}
                </div>
                <div class="col-span-12 md:col-span-4 lg:col-span-6  xl:col-span-6 
                    truncate text-gray-400">
                    {{ item.fields.fldREEp2EsmVJ ? item.fields.fldREEp2EsmVJ : "-"}}
                </div>
                <div class="hidden sm:inline-block md:inline-block lg:inline-block xl:inline-block  
                    col-span-3 md:col-span-2 lg:col-span-1  xl:col-span-1
                text-gray-400">
                    {{ item.fields.fldjxEQ30ZfJD }}
                </div>
                <div class="hidden sm:inline-block md:inline-block lg:inline-block xl:inline-block 
                    col-span-6 md:col-span-2 lg:col-span-1  xl:col-span-1
                    truncate">
                    {{ item.fields.fldkY95wTKPw1 }}
                </div>
                <span class="hidden sm:inline-block md:inline-block lg:inline-block xl:inline-block  
                    col-span-3 md:col-span-1 lg:col-span-1  xl:col-span-1">
                    <t-link theme="primary" @click="viewItem(item)">
                        查看
                    </t-link>
                </span>
            </div>
            <div class="right-icon absolute right-2 top-1/3 inline-block sm:hidden md:hidden lg:hidden xl:hidden"
                @click="h5Page(item)"
            >
                <t-icon class="text-gray-300" name="chevron-right" :style="{'font-size': '26px'}"/>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "D-list",
    //import引入组件等
    components: {},
    props: {
        datas: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    data() {
        return {};
    },
    computed: {},
    watch: {},
    methods: {
        viewItem (e) {
            this.$emit('viewItem' , {data : e})
        },
        h5Page (e) {
            this.$emit('viewH5', {data : e})
        }
    },
    created() { },
};
</script>
<style scoped lang='less'></style>