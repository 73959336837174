<template>
  <div class="w-full">
    
    <template v-if="tabsItems =='6'">
      <div class="mb-3">
        <t-input-number v-model="keNum" min="0" size="large" suffix="个" align="center" style="width: 100%" placeholder="研发数"/>
      </div>
    </template>
    <div>
        <t-input-number :disabled="tabsItems == '6' && keNum == '' ? true : false" 
      :placeholder="tabsItems == '6' ? inputPol.value2 : inputPol.value1" 
      v-model="decimalValue" clearable
        align="center" style="width: 100%;" suffix="万元 " :decimalPlaces="2"
        :format="(value, { fixedNumber }) => `${fixedNumber}`" theme="normal" size="large" @change="thisSum"
        @blur="focusOut" @focus="focusIn" />
    </div>
  </div>
</template>

<script>
import { debounce } from 'xijs';
const format_seg = {
  //财务审计
  "1": [
    { key: 1, "min": 0, "max": 50, "rangmax": 50, "rate": 4 },
    { key: 2, "min": 50, "max": 100, "rangmax": 50, "rate": 2 },
    { key: 3, "min": 100, "max": 500, "rangmax": 400, "rate": 0.9 },
    { key: 4, "min": 500, "max": 1000, "rangmax": 500, "rate": 0.7 },
    { key: 5, "min": 1000, "max": 5000, "rangmax": 4000, "rate": 0.5 },
    { key: 6, "min": 5000, "max": 10000, "rangmax": 5000, "rate": 0.3 },
    { key: 7, "min": 10000, "max": 50000, "rangmax": 40000, "rate": 0.15 },
    { key: 8, "min": 50000, "max": 100000, "rangmax": 50000, "rate": 0.1 },
    { key: 9, "min": 100000, "max": 100000, "rangmax": 100000, "rate": 0.08 }
  ],
  //专项审计
  "2": [
     { key: 1, "min": 0, "max": 50, "rangmax": 50, "rate": 4 },
    { key: 2, "min": 50, "max": 100, "rangmax": 50, "rate": 2 },
    { key: 3, "min": 100, "max": 500, "rangmax": 400, "rate": 0.9 },
    { key: 4, "min": 500, "max": 1000, "rangmax": 500, "rate": 0.7 },
    { key: 5, "min": 1000, "max": 5000, "rangmax": 4000, "rate": 0.5 },
    { key: 6, "min": 5000, "max": 10000, "rangmax": 5000, "rate": 0.3 },
    { key: 7, "min": 10000, "max": 50000, "rangmax": 40000, "rate": 0.15 },
    { key: 8, "min": 50000, "max": 100000, "rangmax": 50000, "rate": 0.1 },
    { key: 9, "min": 100000, "max": 100000, "rangmax": 100000, "rate": 0.08 }
  ],
  //汇算清缴
  "3": [
    { key: 1, "min": 0, "max": 50, "rangmax": 50, "rate": 3 },
    { key: 2, "min": 50, "max": 100, "rangmax": 50, "rate": 3 },
    { key: 3, "min": 100, "max": 500, "rangmax": 400, "rate": 1 },
    { key: 4, "min": 500, "max": 1000, "rangmax": 500, "rate": 0.5 },
    { key: 5, "min": 1000, "max": 5000, "rangmax": 4000, "rate": 0.3 },
    { key: 6, "min": 5000, "max": 10000, "rangmax": 5000, "rate": 0.2 },
    { key: 7, "min": 10000, "max": 50000, "rangmax": 40000, "rate": 0.1 },
    { key: 8, "min": 50000, "max": 100000, "rangmax": 50000, "rate": 0.05 },
    { key: 9, "min": 100000, "max": 100000, "rangmax": 100000, "rate": 0.02 }
  ],
  //土增清算
  "4": [
    { key: 1, "min": 0, "max": 5000, "rangmax": 5000, "rate": 1 },
    { key: 2, "min": 5000, "max": 10000, "rangmax": 5000, "rate": 0.8 },
    { key: 3, "min": 10000, "max": 50000, "rangmax": 40000, "rate": 0.7 },
    { key: 4, "min": 50000, "max": 100000, "rangmax": 50000, "rate": 0.6 },
    { key: 5, "min": 100000, "max": 100000, "rangmax": 100000, "rate": 0.5 }
  ],
  /* 验资收费 
    计费区间 * 费率 + 增加数
    -----------------------
    rangmax : 速算增加数  
    rate: 费率
    -----------------------
  */
  "5": [
    { key: 1, "min": 0, "max": 50, "rangmax": 1200, "rate": 0 },
    { key: 2, "min": 50, "max": 100, "rangmax": 450, "rate": 0.0015 },
    { key: 3, "min": 100, "max": 500, "rangmax": 1550, "rate": 0.0004 },
    { key: 4, "min": 500, "max": 1000, "rangmax": 2050, "rate": 0.0003 },
    { key: 5, "min": 1000, "max": 5000, "rangmax": 3050, "rate": 0.0002 },
    { key: 6, "min": 5000, "max": 10000, "rangmax": 5550, "rate": 0.00015 },
    { key: 7, "min": 10000, "max": 50000, "rangmax": 10550, "rate": 0.0001 },
    { key: 8, "min": 50000, "max": 100000, "rangmax": 20550, "rate": 0.00008 },
    { key: 9, "min": 100000, "max": 9999999999, "rangmax": 40550, "rate": 0.00006 }
  ],  
  /*研发台账
    rangmax：研发项目个数5个以内（含5个）
    rangmax2：研发项目个数5-10个（含10个）
    rangmax3：研发项目个数10个以上
  */
  "6": [
    { key: 1, "min": 0, "max": 200, "rangmax": 3000, "rangmax2": 4000, "rangmax3": 5000, },
    { key: 2, "min": 200, "max": 500, "rangmax": 5000, "rangmax2": 6000, "rangmax3": 8000, },
    { key: 3, "min": 500, "max": 1000, "rangmax": 8000, "rangmax2": 9000, "rangmax3": 10000, },
    { key: 4, "min": 1000, "max": 999999, "rangmax": 10000, "rangmax2": 11000, "rangmax3": 12000, },
  ],
}

export default {
  name: 'ItemSum',
  props: {
    tabs: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      inputPol: {
        value1: "资产总额",
        value2: "当年研发费总额",
        value3: "当年研发项目数"
      },
      keNum: "",
      visible: false,
      format_seg,
      decimalValue: "",
      columns: [
        {
          title: "资产总额", colKey: 'total_info', align: "center", children: [
            { colKey: 'min', title: '最小', align: "center", width: "20" },
            { colKey: 'max', title: '最大', align: "center", width: "20" },
          ]
        },
        {
          title: "费率", colKey: 'rate', align: "center", width: "20"
        }
      ]
    }
  },
  computed: {
    tabsItems: function() {
      let _that = this.tabs.toString()
      return _that.length > 1 ? _that.substr(0,1) : _that
    }
  },
  watch: {
    tabs: {
      immediate: true,
      handler: function (nval, oval) {
        if (oval != null) {
          this.setEmp();
        }
      }
    }
  },
  methods: {
    async thisSum(e) {
      if (!e) {
        e = 0
      }
      var sum_res = []
      let _seg = this.format_seg[this.tabsItems]
      let _grade = 0
      let _last = _seg.length - 1
      let _sum = 0
      if (this.tabsItems == '5') {
        Array.from(_seg, (item, index) => {
          if (item.min < e && item.max >= e) {
            _grade = item.max
            _sum = (e * item.rate * 10000) + item.rangmax
            sum_res.push({
              "key": item.key, 'value': e,
              "interval": index == 0 ? "≦ " + item.min + "万" : item.min + "万 ≦ " + item.max + "万",
              "rate": item.rate,
              "rateRresum": _sum
            })
          } else {
            return false
          }
        })
      } else if (this.tabsItems == '6') {
        Array.from(_seg, (item,index) => {
          if (item.min < e && item.max >= e) {
            let _this = this.keNum
            if(_this){
              if(_this <= 5){
                _sum = item.rangmax
              }else if(_this >5 && _this <=10){
                _sum = item.rangmax2
              }else{
                _sum = item.rangmax3
              }
              sum_res.push({ 
                "key" : item.key ,'value' : e , 
                "interval" : index == 0 ? "≦ " + item.min + "万"  :  item.min + "万 ≦ " + item.max + "万" , 
                "rate": 0, 
                "rateRresum" : _sum
              })
            }else{
              this.$message.error("请先填写项目个数");
              return false
            }
          } else {
            return false
          }
        })
      } else {
        Array.from(_seg, (item, index) => {
          if (index == 0) {
            _grade = item.max
          } else if ((_last - index) == 0) {
            _grade = e > item.max ? e - item.rangmax : 0
          } else {
            _grade = e - item.min
            _grade = _grade > item.rangmax ? item.rangmax : _grade < 0 ? 0 : _grade
          }
          _sum = _grade * item.rate * 10
          if (_sum > 0) {
            sum_res.push({
              "key": item.key, 'value': _grade,
              "interval": index == 0 ? "≦ " + item.max + "万" : item.min + "万 ≦ " + item.max + "万",
              "rate": item.rate,
              "rateRresum": _sum 
            })
          }
        });
      }
      this.$emit("sums", { "data": e, "grade": sum_res });
      return sum_res;
    },
    async deFun(e) {
      let _that = this.thisSum()
      debounce(_that, 200)(e);
    },
    async setEmp() {
      this.decimalValue = ""
      this.keNum = 0
    },
    focusOut(e) {
      if (e === undefined) {
        this.decimalValue = ""
      }
    },
    focusIn(e) {
      if (e == 0) {
        this.decimalValue = ""
      }
    },
    handleClick() {
      let _that = this.visible
      this.visible = !_that
    }
  },

}
</script>

<style scoped></style>
