<template>
  <div>
    <div
      class="list px-5 py-3 grid gap-8 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5"
    >
      <div
        v-for="(items, idx) in datas"
        :key="idx"
        :bordered="true"
        class="rounded-md p-5 pb-3 shadow-lg border bg-yellow-50 border-amber-200"
      >
        <div class="text-xl truncate">
          {{ items.fields.fldu6FfahVzjX }}
        </div>
        <div class="py-5 text-base text-gray-500 truncate">
          {{ items.fields.fldREEp2EsmVJ ? items.fields.fldREEp2EsmVJ : "-" }}
        </div>
        <div id="footer">
          <div
            class="grid grid-cols-6 gap-8 justify-items-center border-t pt-3"
          >
            <!-- <div class="text-gray-400 col-span-3 text-gray-4 textClamp">
              {{ items.fields.fldP8eEwSZ5wv }}
            </div>
            <div class="col-span-2">
              <t-divider layout="vertical" />
            </div> -->
            <div class="text-lg">
              <t-icon name="browse" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "D-Grid",
  components: {},
  props: {
    datas: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
};
</script>
<style scoped lang="less"></style>
