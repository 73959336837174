import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import xijs from 'xijs';
import _ from 'lodash'

import TDesign from 'tdesign-vue';
// 引入组件库的少量全局样式变量
import 'tdesign-vue/es/style/index.css';
import "./assets/tailwindcss.css";

//全局过滤器
import * as filters from '@/utils/filters.js'
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.use(TDesign)

Vue.prototype.xijs = xijs
Vue.config.productionTip = false
Vue.prototype._ = _

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')