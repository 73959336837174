<template>
  <div class="mt-0 xl:mt-10 lg:mt-10 md:mt-10 sm:mt-10">
  <div class="grid grid-cols-12 gap-4 xl:grid-cols-11 lg:grid-cols-10 md:grid-cols-6 sm:grid-cols-8">
    
    <div
      class="mx-0 p-3 col-start-1 col-span-12 xl:col-start-4 xl:col-span-4 lg:col-start-3 lg:col-span-6 md:col-start-2 md:col-span-5 sm:col-start-2 sm:col-span-6">
      <div class="grid grid-cols-2 gap-4 mb-3 text-white text-base">
        <div :class="`rounded p-3 px-4 ${costSet[0].color} ${currentTabs > 4 ? 'col-span-2' : ''}`">
          <div class="text-slate-200">{{ costSet[0].name }}（元）</div>
          <div class="mt-1 text-2xl text-center">
            {{
              sumsArr.summaryData > 0
              ? $options.filters.toFixData(
                (sumsArr.summaryData * costSet[0].discount) * asTabs
              )
              : "-"
            }}
          </div>
        </div>
        
        <div :class="`rounded p-3 px-4 ${costSet[1].color}`" v-if="currentTabs < '5' ? true : false ">
          <div class="text-slate-200">{{ costSet[1].name }}（元）</div>
          <div class="mt-1 text-2xl text-center">
            {{
              sumsArr.summaryData > 0
              ? $options.filters.toFixData(
                (sumsArr.summaryData * costSet[1].discount) * asTabs
              )
              : "-"
            }}
          </div>
        </div>
      </div>
      <div class="px-5 py-5 bg-white rounded relative z-50">
        <div class="flex items-start grid grid-cols-12 lg:grid-cols-6 gap-2">
          <div class="col-span-5 lg:col-span-2 md:col-span-4 sm:col-span-5">
            <tabs @current="currentTab" />
          </div>
          <ItemSum ref="ItemSumM" :tabs="currentTabs" @sums="sumCompon"
            class="col-span-7 lg:col-span-4 md:col-span-8 sm:col-span-7" />
        </div>
        <div class="my-3 block">
          <div class="p-2 rounded-sm border-rose-300 text-red-700 bg-red-50 border block truncate "
            variant="light-outline" size="large" theme="danger">计算金额：{{ sumsArr.sumsD | capital }}</div>
        </div>
        <t-table bordered class="h-66 mt-3" maxHeight="100%" size="small" row-key="index"   :data="sumsArr.coldata"
          :columns="sumsArr.columns" :loading="loading" empty="请输入资产总额计算结果" />
        <div
          class="border-1 text-center py-5 z-80 fixed lg:static md:static sm:static left-0 right-0 bottom-0 bg-white block">
          <t-space>
            <t-button size="large" @click="renderTable"> 计算结果 </t-button>
            <t-button size="large" theme="default" @click="reSet()">
              重置
            </t-button>
          </t-space>
        </div>
    </div>
  </div>
  </div>
</div>
</template>

<script>
import tabs from "./components/tabsItem.vue";
import ItemSum from "./components/ItemSum.vue";
import { capitalizedAmount, formatNumber, floatMul } from "xijs";

const costSet = [
  { name: "标准收费", discount: 1, color: "bg-blue-700" },
  { name: "七折收费", discount: 0.7, color: "bg-emerald-700" },
];

export default {
  name: "HelloIndex",
  components: {
    tabs,
    ItemSum,
  },
  data() {
    return {
      loading: false,
      costSet,
      costStrEnd: "收费（元）",
      currentTabs: 1,
      renderEd: [],
      sumsArr: {
        sumsD: 0,
        formatSum: 0,
        coldata: [],
        summaryData: 0,
        columns: [
          {
            colKey: "rateRresum",
            title: "标准收费",
            width: "80",
            align: "center",
            fixed: "left",
            // foot: () => {
            //   let _summary = this.summary()
            //   return formatNumber(floatMul(_summary,1).toFixed(2))
            // },
            cell: (h, { row }) => {
              let _val =
                row.rateRresum > 0
                  ? formatNumber(floatMul(row.rateRresum, 1).toFixed(2))
                  : "-";
              return _val;
            },
          },
          {
            colKey: "value",
            title: "总额",
            width: "80",
            align: "center",
            cell: (h, { row }) => {
              return row.value > 0 ? formatNumber(row.value) : "-";
            },
          },
          {
            colKey: "interval",
            title: "资产总额（万元）",
            align: "center",
            width: "200",
            cell: (h, { row }) => {
              return row.interval == "100000万 ≦ 9999999999万"
                ? "≦ 100000万"
                : row.interval;
            },
          },
          { colKey: "rate", title: "费率", align: "center", width: "100" },
        ],
      },
    };
  },
  filters: {
    capital: function (val) {
      return (
        formatNumber(val.toFixed(2)) +
        " 万（ " +
        capitalizedAmount(val * 10000) +
        " ）"
      );
    },
  },
  computed: {
    asTabs: function () {
      let _that = this.currentTabs
      // console.log(_that,_that.toString().splice(0,1));
      let _this = 1
      switch (_that){
        case 21:
          _this =  1.5;
           break;
        case 22:
          _this =  1.5;
           break;
        case 23:
          _this =  2;
           break;
        case 24:
          _this =  1.2;
           break;
        default:
          _this = 1
          break;
      }
      return _this;
    },
  },
  methods: {
    sumCompon(e) {
      this.sumsArr.sumsD = e.data;
      var _cap = capitalizedAmount(e.data * 10000);
      this.sumsArr.formatSum = _cap;
      this.renderEd = e.grade;
    },
    currentTab(e) {
      this.reSet();
      this.currentTabs = Number(e);
    },
    renderTable() {
      if (this.sumsArr.sumsD) {
        this.loading = true;
        setTimeout(() => {
          this.sumsArr.coldata = this.renderEd;
          this.summary();
          this.loading = false;
        }, 500);
      } else {
        this.$message.error("请先输入资产总额金额");
        return false;
      }
    },
    summary() {
      let _that = this.sumsArr.coldata;
      let summary = 0;
      Array.from(_that, (item) => {
        summary += item.rateRresum;
      });
      this.sumsArr.summaryData = summary;
      return summary;
    },
    reSet() {
      let _that = this;
      this.$nextTick(() => {
        _that.sumsArr.summaryData = 0;
        _that.sumsArr.formatSum = 0;
        _that.sumsArr.sumsD = 0;
        _that.sumsArr.coldata = [];
        _that.$refs.ItemSumM.setEmp();
      });
    },
  },
};
</script>
<style scoped></style>
