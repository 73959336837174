import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
import home from "@/components/Hello.vue";
import dirct from "@/components/direct.vue";

const routes = [
  {
    path: "/",
    component: home,
    meta: {
      title: "收费测算",
    }
  },
  {
    path: "/dirct",
    component: dirct,
    meta: {
      title: "业务指导目录",
    }
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router;
