<template>
  <div>
    <t-dialog :visible.sync="visible" :closeOnOverlayClick="false" :footer="false" width="580px"
    :header="data.fldu6FfahVzjX" placement="center"
    >
      <template #body>
        <!-- {{ data }} -->
        <div>
          <div v-for="(item,index) in listTitle" :key="index" class="text-base text-black grid grid-cols-4 py-3 border-b">
            <div class="col-span-1 text-gray-500">
              {{item.name}}
            </div>
            <div class="col-span-3">
              {{ data[item.fields] ? data[item.fields] : "-" }}
            </div>
          </div>
        </div>
        <div class="mt-8">
          <t-tag size="large" theme="warning" variant="outline" class="mr-3">{{ data.fldjxEQ30ZfJD }}</t-tag>
          <t-tag size="large" theme="warning" variant="outline">{{ data.fldkY95wTKPw1 }}</t-tag>
        </div>        
      </template>
    </t-dialog>
  </div>
</template>
<script>
const listTitle = [
  { name: "业务分类", fields: "fldjxEQ30ZfJD" },
  { name: "属性", fields: "fldkY95wTKPw1" },
  { name: "内容简介", fields: "fldREEp2EsmVJ" },
  { name: "法律依据", fields: "fldEDBJ1kYNBn" },
  { name: "报告使用者", fields: "fldYeImYafAsL" },
  { name: "委托人", fields: "fldtokY8WfXOE" },
  { name: "胜任要求", fields: "fldSf8Paloitg" },
  { name: "业务开展情况", fields: "fldyfqewtlZr5" },
];

export default {
  name: "D-Diag",
  components: {},
  props: {
    datas: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      visible: false,
      data: [],
      listTitle
    };
  },
  computed: {},
  watch: {},
  methods: {
    onVisible(e) {
      if (e) {
        this.visible = true
        this.data = e.data.fields
      } else {
        this.$message.error("传参出错")
      }
    }
  },
  created() { },
};
</script>
<style scoped lang="less"></style>
