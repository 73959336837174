<template>
    <div class="bg-white rounded-md m-3 relative
        p-5 sm:p-10 md:p-10 lg:p-10 xl:p-10
    ">
        <!-- //搜索框 -->
        <div class="bg-white Search text-center top-8 left-0 right-0 fixed z-50 mx-5 p-5" v-if="search.visiable">
            <input @keyup.enter="searchV" type="text"
                class="w-full xl:w-1/3 xl:w-1/3 xl:w-1/3 xl:w-1/3 
                px-6 ring-4 py-3 border border-gray-200 rounded-md focus:outline-none focus:ring-4 focus:ring-blue-600 focus:border-blue-600"
                placeholder="输入关键词搜索，按回车搜索" />
            <t-icon name="close-circle" class="ml-0 sm:ml-6 md:ml-6 lg:ml-6 xl:ml-6 
            mt-3 sm:mt-0 md:mt-0 lg:mt-0 xl:mt-0
            cursor-pointer text-3xl text-blue-700" @click="() => {
                search.visiable = false;
                search.value = '';
            }
                " />
        </div>
        <div v-if="loading" class="">
            <t-message theme="loading" class="mx-auto block mb-5">数据记载中</t-message>
            <t-skeleton :loading="loading" />
        </div>
        <div v-else>
            <div class="mb-3 flex 
                flex-row sm:flex-row md:flex-row lg:flex-row xl:flex-row
                justify-between 
                items-center">
                <!-- //机构 -->
                <t-space class="hidden sm:flex md:flex lg:flex xl:flex">
                    <t-button @click="typeChange(index)" shape="square" variant="text"
                        :class="`border-none hover:bg-blue-500  hover:shadow-blue-500/50 hover:text-white ${typeTabs.current == index ? 'bg-blue-500 shadow-lg shadow-blue-500/50 text-white' : ''}`"
                        v-for="(item, index) in typeTabs.listType" :key="index">
                        <t-icon :name="item.icon" :title="item.name" size="22px" />
                    </t-button>
                </t-space>
                <div class="flex items-center sm:my-5">
                    <t-radio-group size="large" variant="primary-filled" :default-value="idx" @change="current">
                        <t-radio-button :value="index" v-for="(item, index) in data" :key="index">
                            {{ item.keyname }}
                        </t-radio-button>
                    </t-radio-group>
                </div>
                <t-button theme="primary" size="large" @click="search.visiable = true"
                    class="bg-blue-500 shadow-lg shadow-blue-500/50" :disabled="search.visiable">搜索</t-button>
            </div>
            <!-- //一级类目 -->

            <div class="mt-6 mb-3">
                <div>
                    <div v-if="search.value" class="border-r border-dotted mb-5">
                        <span class="inline-block p-3 text-red-500 border-red-100 bg-red-50">
                            搜索的关键词：{{ search.value }}
                        </span>
                    </div>
                    <div v-else class="scroll-view">
                        <div style="max-width:100%;" v-for="(its, idx) in cas.items" :key="idx" @click="curTitle(idx)" :class="`cursor-pointer inline-block rounded-md mr-3 px-3 py-2
                        ${cas.value == idx
                                ? 'text-white bg-blue-500 shadow-lg shadow-blue-500/50'
                                : ''
                            }
                        hover:text-white hover:bg-blue-500 hover:shadow-lg hover:shadow-blue-500/50`">
                            {{ idx }}
                        </div>
                    </div>
                </div>
            </div>

            <t-message v-if="cLoading" theme="loading" class="mx-auto block my-16">数据记载中</t-message>
            <div v-else>
                <component :is="typeTabs.current ? 'DGrid' : 'DList'" @viewItem="viewDiag" @viewH5="viewDrawer "
                    :datas="search.value ? $options.filters['searchData'](search.value) : cas.items[cas.value]" />
            </div>
            <Diag ref="Diag"/>
            <Drawer ref="Drawer"/>
        </div>
        <!-- {{ search.value ? $options.filters['searchData'](search.value) : cas.items[cas.value] }} -->
    </div>
</template>
<script>
import { Vika } from "@vikadata/vika";
const vika = new Vika({ token: "uskG6LVmEvLqqR6braIBvQY", fieldKey: "id" });
const datasheet = vika.datasheet("dst8NXrc2TVYwjN69g"); //指导目录表
import DList from "./components/directList.vue";
import DGrid from "./components/directGrid.vue";
import Diag from "./components/directDiag.vue";
import Drawer from "./components/directDrawer.vue";

const listType = [
    { name: "List", icon: "menu-fold" },
    { name: "Grid", icon: "view-module" },
]

let that;
export default {
    name: "DirectIndex",
    data() {
        return {
            typeTabs: {
                listType,
                current: 0
            },
            loading: true,
            cLoading: true,
            search: {
                visiable: false,
                value: "",
            },
            resoure: [],
            data: [],
            idx: 0,
            cas: {
                children: 0,
                items: [],
                value: [],
            },
            watDep: "",
        };
    },
    components: {
        DList,
        DGrid,Diag,Drawer
    },
    beforeCreate() {
        that = this;
    },
    created() {
        this.FormData();
    },
    watch: {
        cas: {
            deep: true,
            handler: function (nVal) {
                this.watDep = nVal.value;
            },
        },
    },
    filters: {
        searchData(e) {
            let _this = that.resoure;
            let res = _this.filter((item) => {
                let _val = item.fields.fldu6FfahVzjX;
                return _val.includes(e);
            });
            return res;
        },
    },
    methods: {
        async FormData() {
            let res = await this.dirctRec();
            this.resoure = res;
            res = this._.groupBy(res, "fields.fldjxEQ30ZfJD");
            let arr = [];
            this._.forEach(res, (val, key) => {
                let _val = this._.groupBy(val, "fields.fldkY95wTKPw1");
                let _v = {
                    keyname: key,
                    items: _val,
                };
                arr.push(_v);
            });

            this.data = arr;
            this.current(0);
            this.loading = false;
        },
        async dirctRec() {
            let res = await datasheet.records
                .query({
                    pageSize: 1000,
                    viewId: "viw3tp0AZRo9D",
                })
                .then((response) => {
                    if (response.success) {
                        return response.data.records;
                    } else {
                        return response;
                    }
                });
            return res;
        },
        current(e) {
            let _that = this.data;
            this.cas.children = e;
            this.cas.items = _that[e].items;
            this.cas.value = e == 0 ? "报表审计" : "纳税申报代理";
            this.cLoading = false;
        },
        curTitle(e) {
            this.cLoading = true;
            this.cas.value = e;
            setTimeout(() => {
                this.cLoading = false;
            }, 500);
        },
        searchV(e) {
            let _e = e.target.value;
            if (_e) {
                this.search.value = _e;
                this.loadStatus()
            } else {
                this.$message.error("搜索内容不能为空");
            }
        },
        typeChange(e) {
            console.log(e);
            this.typeTabs.current = e
            this.loadStatus();
        },
        viewDiag (e) {
            this.$refs.Diag.onVisible(e)
        },
        viewDrawer (e) {
            this.$refs.Drawer.onVisible(e)
        },
        loadStatus() {
            this.cLoading = true
            setTimeout(() => {
                this.cLoading = false;
            }, 500);
        }
    },
};
</script>
<style>
.textClamp {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.scroll-view{
  height: 65px;
  overflow:hidden;
  white-space:nowrap;
  width: 100%;
  display: -webkit-box; 
  overflow-x: auto;
  overflow-y: hidden;
}
</style>
