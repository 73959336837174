<template>
  <div class="relative">
    <t-drawer mode="push" :destroyOnClose="true" :visible.sync="visible" :header="false" :footer="false" size="100%" :show="showInit">
      <template #body>
          <div class="absolute bg-blue-600 left-0 right-0 top-0 h-28">
            <div class="mt-3 px-3 toolbar flex justify-between  text-white">
              <div class="back " @click="visible = false">
                <t-button shape="square" variant="outline" class="bg-blue-700 border-blue-700 text-white">
                  <t-icon name="chevron-left" size="26px" />
                </t-button>
              </div>
              <!-- <div class="share text-xl">
                <t-icon name="share" />
              </div> -->
            </div>
            <div class="absolute inset-x-0 -bottom-10 h-20">
              <div class="shadow text-xl rounded-md border w-4/5 mx-auto bg-white h-20 text-center px-5">
                <div class="grid grid-cols-1 gap-4 content-center h-20">
                  <div class="self-center text-black text-2xl">{{ data.fldu6FfahVzjX }}</div>
                </div>
              </div>
              <div class="relative px-3 top-5 bottom-3 text-base leading-7" :style="{'height': vhHeight,'overflow-y': 'scroll'}">
                <div v-for="(item, index) in listTitle" :key="index" class="py-3 px-2 border-b border-dotted">
                  <div class="float-left w-1/3 text-gray-400">
                    {{ item.name }}
                  </div>
                  <div class="float-right w-2/3 text-right">
                    {{ data[item.fields] ? data[item.fields] : '-' }}
                  </div>
                  <div class="clear-both"></div>
                </div>
              </div>
            </div>
          </div>
      </template>
    </t-drawer>
  </div>
</template>
<script>
const listTitle = [
  { name: "业务分类", fields: "fldjxEQ30ZfJD" },
  { name: "属性", fields: "fldkY95wTKPw1" },
  { name: "内容简介", fields: "fldREEp2EsmVJ" },
  { name: "法律依据", fields: "fldEDBJ1kYNBn" },
  { name: "报告使用者", fields: "fldYeImYafAsL" },
  { name: "委托人", fields: "fldtokY8WfXOE" },
  { name: "胜任要求", fields: "fldSf8Paloitg" },
  { name: "业务开展情况", fields: "fldyfqewtlZr5" },
];
export default {
  name: "D-Diag",
  components: {},
  props: {},
  data() {
    return {
      visible: false,
      data: [],
      listTitle,
      vhHeight: "20px",
    };
  },
  computed: {},
  watch: {},
  methods: {
    onVisible(e) {
      if (e) {
        this.visible = true;
        this.data = e.data.fields;
        this.showInit();
      } else {
        this.$message.error("传参出错");
      }
    },
    showInit () {
      this.$nextTick(() => {
        this.vhHeight = (window.innerHeight - 200) + "px"
      });      
    }
  },
  created() {
  }
};
</script>
<style scoped>
  .t-VHE{
    height:80vh;
  }
</style>
