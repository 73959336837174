<template>
  <div class="flex">
    <t-cascader
      size="large"
      :borderless="false"
      :options="Items"
      v-model="value1"
      :show-all-levels="false"
      placeholder="请选择"
      defaultInputValue="1"
      class="text-center"
      @change="thisItem"
    >
  </t-cascader>
  </div>
</template>

<script>
const Items = [
  { label: "财务审计", value: "1" },
  {
    label: "专项审计",
    value: "2",
    children: [
      {
        label: "合并、分立、清算审计",
        value: "21",
      },
      {
        label: "经济责任审计",
        value: "22",
      },
      {
        label: "清产核资",
        value: "23",
      },
      {
        label: "其他审计及鉴证业务",
        value: "24",
      },
    ],
  },
  { label: "税务审计", value: "3" },
  { label: "土增清算", value: "4" },
  { label: "验资", value: "5" },
  { label: "研发台账", value: "6" },
];
export default {
  data() {
    return {
      value1: "1",
      Items,
    };
  },
  methods: {
    thisItem(e) {
      console.log(e);
      // console.log(e);
      this.$emit("current", e);
    },
  },
};
</script>

<style scoped>
  .t-cascader__item{
    padding: 5px 0px;
  }
</style>
